import axios from 'axios';

export const getResellers = () => {
  return axios.get(`organizations`, { params: { perPage: 999 } });
};

export const getOrganizations = resellerId => {
  return axios.get('organizations', { params: { 'reseller.organizationId': resellerId, perPage: 999 } });
};

export const getAvailablePackages = (selectedPackages, all) => {
  return axios.get('synchronizer/packages/connectable', { params: { usedPackages: selectedPackages, all } });
};

export const getAvailableCredentialForPackageType = (packageType, organizationId) => {
  return axios.get(`credentials`, {
    params: { packageType, 'organization.organizationId': organizationId, perPage: 999 },
  });
};

export const getCredentialSettings = (credential, usedPackages) => {
  return axios.get(`credential/${credential}/setting-details`, {
    params: { usedPackages },
  });
};

export const getConnectionSettings = connectionId => {
  return axios.get(`connections/${connectionId}/setting-details`);
};

export const getStatusOfInitialRun = connectionId => {
  return axios.get(`${connectionId}/runs-limited`);
};

export const getNewCredentialDetails = packageType => {
  return axios.get(`synchronizer/packages/${packageType}/credentials-details`);
};

export const getPackageTypes = () => {
  return axios.get('synchronizer/package-types');
};

export const getPackageRelations = packageType => {
  return axios.get('synchronizer/package-types', { params: { packageType } });
};

export const getCredentialForPackageType = packageType => {
  return axios.get(`synchronizer/package-types/${packageType}/credential`);
};

export const getSettingsForPackageType = packageType => {
  return axios.get(`synchronizer/package-types/${packageType}/settings`);
};

export const onboardNewConnection = async values => {
  return axios.post(`reseller/${values.resellerId}/onboard`, { ...values });
};

export const onboardNewOrganization = async values => {
  return axios.post(`organizations`, { ...values });
};

export const onboardUpdateOrganization = async values => {
  return axios.put(`organizations/${values.organizationId}`, { ...values });
};

export const onboardNewCredential = async values => {
  return axios.post(`credentials`, { ...values });
};

export const convertExcelToJSON = async formData => {
  return axios.post(`convert-excel-to-json`, formData);
};

export const onboardUpdateCredential = async values => {
  return axios.put(`credentials/${values.credentialId}`, { ...values });
};

export const getAllPackages = () => {
  return axios.get('synchronizer/packages/connectable', { params: { all: true } });
};

export default {
  getResellers,
  getPackageTypes,
  getPackageRelations,
  getCredentialForPackageType,
  getSettingsForPackageType,
  onboardNewConnection,
  onboardNewOrganization,
  onboardUpdateOrganization,
  onboardNewCredential,
  onboardUpdateCredential,
};
