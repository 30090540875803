import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { forOwn, isEmpty, set } from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingSettingsForm from '../Forms/OnboardingSettingsForm/OnboardingSettingsForm';
import { getCredentialSettings } from '../../Services/OnboardingService';
import { FieldAdapter } from '../../../../common/YupValidations/FieldAdapter';
import { createValidationByKeys } from '../../../../common/YupValidations';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';

const OnboardingCredentialsSettingsStep = ({ formRef, onSubmit, credentials, usedPackages, defaults, disabled }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const { oldValues } = useSelector(state => state.OnboardingReducer);

  const loadCredentialSettings = async credentialId => {
    setLoading(true);
    const response = await getCredentialSettings(credentialId, usedPackages);
    setSettings(prevState => [...prevState, response.data['hydra:member']]);
    setLoading(false);
  };

  const setInitialValuesAndValidations = () => {
    if (!isEmpty(oldValues) && !isEmpty(oldValues.values)) {
      dispatch(OnboardingActions.SetCanGoBack(true));
    }
    dispatch(OnboardingActions.SetValues({}));
    if (!settingsLoaded) {
      return;
    }
    let initialSchema = Yup.object().shape({});
    setLoading(true);
    settings.forEach(credentialSettings =>
      credentialSettings.forEach(credential => {
        credential.fields.forEach(field => {
          const fieldAdapter = FieldAdapter(field);
          const fieldValidation = fieldAdapter.getValidation();
          const objectValidation = createValidationByKeys(
            [field.fieldName, credential.packageType, 'settings'],
            fieldValidation,
          );
          initialSchema = initialSchema.concat(objectValidation);

          let initialValue;
          if (!isEmpty(defaults[credential.packageType])) {
            initialValue = defaults[credential.packageType][field.fieldName];
          }

          if (!initialValue) {
            initialValue = fieldAdapter.getInitialValue(initialValue);
          }
          setInitialValues(prevState => {
            const currentState = Object.assign({}, prevState);
            set(currentState, ['settings', credential.packageType, field.fieldName], initialValue);
            return currentState;
          });
        });
      }),
    );
    setValidationSchema(initialSchema);
    setLoading(false);
  };

  useEffect(() => {
    if (isEmpty(settings)) {
      forOwn(credentials.credentials, credentialId => {
        loadCredentialSettings(credentialId);
      });
    }
    setSettingsLoaded(Object.keys(credentials.credentials).length === settings.length);
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (settingsLoaded) {
      setInitialValuesAndValidations();
    }
    // eslint-disable-next-line
  }, [settingsLoaded]);

  return (
    <OnboardingSettingsForm
      settings={settings}
      onSubmit={onSubmit}
      loading={loading}
      formRef={formRef}
      disabled={disabled}
      initialValues={initialValues}
      settingsLoaded={settingsLoaded}
      validationSchema={validationSchema}
      tasksWithStatuses={[]}
    />
  );
};

OnboardingCredentialsSettingsStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  defaults: PropTypes.array,
};

OnboardingCredentialsSettingsStep.defaultProps = {
  defaults: [],
};

export default OnboardingCredentialsSettingsStep;
