import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  LoginUser: '[App] Login',
  LogoutUser: '[App] Logout',
  SetUser: '[App] Set User',
  SetLanguage: '[App] Set Language',
  SetInitialPath: '[App] Set initial path',
  RequestVatCodes: '[App] request vat codes',
  FulfilledVatCodes: '[App] fulfilled vat codes',
  SetLoading: '[App] Set loading',
  SetPlatformSettings: '[App] Set Platform settings',
  DisplayMessage: '[App] Display message',
  DeleteMessage: '[App] Delete message',
};

const initialState = {
  initialPath: '/',
  loggedIn: false,
  user: {},
  authToken: null,
  language: 'en',
  vatCodes: [],
  loading: false,
  platformSettings: {
    themeSettings: {
      black: '#000000',
      white: '#FFFFFF',
      primary: {
        contrastText: '#FFFFFF',
        dark: '#0E009F',
        main: '#5A2CD1',
        light: '#925BFF',
      },
      secondary: {
        contrastText: '#FFFFFF',
        dark: '#004ECB',
        main: '#0079FF',
        light: '#6AA7FF',
      },
      success: {
        contrastText: '#FFFFFF',
        dark: '#1b5e20',
        main: '#43a047',
        light: '#66bb6a',
      },
      info: {
        contrastText: '#FFFFFF',
        dark: '#0d47a1',
        main: '#1e88e5',
        light: '#42a5f5',
      },
      warning: {
        contrastText: '#FFFFFF',
        dark: '#e65100',
        main: '#fb8c00',
        light: '#ffa726',
      },
      error: {
        contrastText: '#FFFFFF',
        dark: '#b71c1c',
        main: '#e53935',
        light: '#ef5350',
      },
      text: {
        primary: '#263238',
        secondary: '#546e7a',
        link: '#1e88e5',
      },
      background: {
        default: '#F4F6F8',
        paper: '#FFFFFF',
      },
      icon: '#546e7a',
      divider: '#eeeeee',
    },
    platformName: 'Databrydge',
    logoUrl: 'https://databrydge.com/wp-content/uploads/2019/05/DataBrydge-main-logo-white.png',
    faviconUrl: 'https://databrydge.com/wp-content/uploads/2019/05/cropped-DataBrydge-orange-favicon-192x192.png',
  },
};

export const reducer = persistReducer({ storage, key: 'auth' }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LoginUser: {
      return {
        ...state,
        authToken: action.payload.authToken,
        mercureToken: action.payload.mercureToken,
        user: action.payload.user,
        loggedIn: true,
      };
    }

    case actionTypes.SetLanguage: {
      return {
        ...state,
        language: action.payload.language,
      };
    }
    case actionTypes.SetPlatformSettings: {
      return {
        ...state,
        platformSettings: action.payload.platformSettings,
      };
    }

    case actionTypes.SetUser: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case actionTypes.SetInitialPath: {
      return {
        ...state,
        initialPath: action.payload.initialPath,
      };
    }

    case actionTypes.FulfilledVatCodes: {
      return {
        ...state,
        vatCodes: action.payload,
      };
    }

    case actionTypes.LogoutUser: {
      return {
        ...state,
        authToken: null,
        user: {},
        loggedIn: false,
      };
    }

    case actionTypes.SetLoading: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case actionTypes.DisplayMessage: {
      return {
        ...state,
        action: action.action,
      };
    }

    case actionTypes.DeleteMessage: {
      return {
        ...state,
        action: undefined,
      };
    }

    default:
      return state;
  }
});

export const actions = {
  login: (authToken, mercureToken, user) => {
    return {
      type: actionTypes.LoginUser,
      payload: {
        authToken,
        mercureToken,
        user,
      },
    };
  },
  setUser: user => {
    return {
      type: actionTypes.SetUser,
      payload: {
        user,
      },
    };
  },
  setPlatformSettings: platformSettings => {
    return {
      type: actionTypes.SetPlatformSettings,
      payload: {
        platformSettings,
      },
    };
  },
  setInitialPath: initialPath => {
    return {
      type: actionTypes.SetInitialPath,
      payload: {
        initialPath,
      },
    };
  },
  setLanguage: language => {
    return {
      type: actionTypes.SetLanguage,
      payload: {
        language,
      },
    };
  },
  logout: () => ({
    type: actionTypes.LogoutUser,
  }),
  setLoading: loading => {
    return {
      type: actionTypes.SetLoading,
      loading,
    };
  },
  displayMessage: action => ({
    type: actionTypes.DisplayMessage,
    action,
  }),
  deleteMessage: () => ({
    type: actionTypes.DeleteMessage,
  }),
};
