import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CardContent } from '@material-ui/core';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { actions as LogEntriesActions } from '../../Ducks/LogEntries.duck';
import { actions as TasksActions } from '../../Ducks/Tasks.duck';
import { actions as ConnectionActions } from '../../Ducks/Connection.duck';
import { actions as SyncStatusActions } from '../../Ducks/SyncStatus.duck';
import LogsTable from '../../Components/LogsTable';
import TasksTable from '../../Components/TasksTable';
import {
  clearConnectionLogs,
  clearConnectionTasks,
  updateAllConnectionLogsStatus,
  updateConnection,
  updateSelectedConnectionLogsStatus,
} from '../../Services/ConnectionService';
import ChangeConnectionWizard from '../../Components/Change/ChangeConnectionWizard';
import SyncStatusTable from '../../Components/SyncStatusTable';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR } from '../../../../common/constants';
import { LOG_STATUS } from './logStatusConstants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  buttonElement: {
    marginRight: theme.spacing(1),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ display: 'inline-block' }}>
    <RouterLink {...props} />
  </div>
));

const ConnectionDetailsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { connectionId } = useParams();
  const [clearingTasks, setClearingTasks] = useState(false);
  const [clearingLogs, setClearingLogs] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [runButtonDisabled, setRunButtonDisabled] = useState(false);
  const [updatingAllLogsStatus, setUpdatingAllLogsStatus] = useState(false);
  const [updatingSelectedLogsStatus, setUpdatingSelectedLogsStatus] = useState(false);

  const { loading, item: connection } = useSelector(state => state.ConnectionReducer);
  const {
    page: pageLogs,
    pageSize: pageSizeLogs,
    loading: loadingLogs,
    items: logs,
    totalCount: totalCountLogs,
    // canDownload,
    // checkingDownload,
  } = useSelector(state => state.LogEntriesReducer);
  const { page, pageSize, loading: loadingTasks, items: tasks, totalCount } = useSelector(state => state.TasksReducer);
  const { user } = useSelector(state => state.AppReducer);

  const { loading: loadingSyncStatusReport, syncStatusReport } = useSelector(state => state.SyncStatusReducer);

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
  }, [dispatch]);

  const isUserSuperAdmin = useMemo(() => {
    return user.roles.includes('ROLE_SUPER_ADMIN');
  }, [user]);

  useEffect(() => {
    if (connection !== undefined && Object.keys(connection).length === 0 && !loading) {
      history.push('/not-found');
    } else if (connection !== undefined && Object.keys(connection).length > 0 && !loading) {
      dispatch(LogEntriesActions.setConnectionId(connectionId));
      dispatch(LogEntriesActions.requestData(connectionId));

      // dispatch(LogEntriesActions.checkCanDownloadMissingDetails());
      dispatch(TasksActions.setConnectionId(connectionId));
      dispatch(TasksActions.requestData(connectionId));
      dispatch(SyncStatusActions.setConnectionId(connectionId));
      dispatch(SyncStatusActions.requestData(connectionId));
    }
  }, [loading, connection, dispatch]);

  const handleClickChange = () => {
    setModal2Open(true);
  };

  const handleChangeConnection = async data => {
    await updateConnection(data);
    setModal2Open(false);
    window.location.reload();
  };

  const handleRunConnectionModule = async conn => {
    const payload = {
      connectionId: conn,
      parameters: {},
    };
    try {
      dispatch(ConnectionActions.forceRun(payload));
    } catch (e) {
      console.log(e);
    }
    dispatch(ConnectionActions.requestData(connectionId));
  };

  const handleClickResetSyncItems = async conn => {
    const payload = {
      connectionId: conn,
      parameters: {},
    };

    dispatch(ConnectionActions.resetSyncStatus(payload));

    await new Promise(resolve => setTimeout(resolve, 1000));

    dispatch(SyncStatusActions.requestData(connectionId));
  };

  const clearTasks = async force => {
    setClearingTasks(true);
    try {
      await clearConnectionTasks(connectionId, force);
    } catch (error) {
      alert('Failed to clear tasks');
    }
    dispatch(TasksActions.requestData(connectionId));
    setClearingTasks(false);
  };

  const clearLogs = async old => {
    setClearingLogs(true);
    try {
      await clearConnectionLogs(connectionId, old);
    } catch (error) {
      alert('Failed to clear logs');
    }
    dispatch(LogEntriesActions.requestData(connectionId));
    setClearingLogs(false);
  };

  const handleToggleLog = log => {
    dispatch(LogEntriesActions.toggleLog(log));
  };

  const handleToggleAllLogs = () => {
    dispatch(LogEntriesActions.toggleAllLogs(logs));
  };

  const handleClickUpdateAllLogsStatus = async status => {
    setUpdatingAllLogsStatus(true);
    try {
      await updateAllConnectionLogsStatus(connectionId, status);
    } catch (error) {
      setUpdatingAllLogsStatus(false);
      dispatch(
        AppActions.displayMessage({
          severity: MESSAGE_SEVERITY_ERROR,
          message: 'connectionDetails.logEntries.allLogsStatusUpdatedError',
        }),
      );
    }
    dispatch(LogEntriesActions.requestData(connectionId));
    setUpdatingAllLogsStatus(false);
  };

  const handleClickUpdateSelectedLogsStatus = async status => {
    setUpdatingSelectedLogsStatus(true);
    const logIds = logs.filter(item => item.selectedRow).map(item => item.mongoId);
    try {
      await updateSelectedConnectionLogsStatus(connectionId, status, logIds);
    } catch (error) {
      setUpdatingSelectedLogsStatus(false);
      dispatch(
        AppActions.displayMessage({
          severity: MESSAGE_SEVERITY_ERROR,
          message: 'connectionDetails.logEntries.selectedLogsStatusUpdatedError',
        }),
      );
    }
    dispatch(LogEntriesActions.requestData(connectionId));
    setUpdatingSelectedLogsStatus(false);
  };

  const retrieveSyncStatusReport = () => {
    dispatch(SyncStatusActions.requestData(connectionId));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(TasksActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(TasksActions.setPageSize(event.target.value));
  };

  const handlePageChangeLogs = (event, newPage) => {
    dispatch(LogEntriesActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChangeLogs = event => {
    dispatch(LogEntriesActions.setPageSize(event.target.value));
  };

  // const downloadLogs = async () => {
  //   try {
  //     const response = await downloadMissingDetails(connectionId);
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', response.headers['x-filename']);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const connectionUsesPackage = packageType => {
    if (connection === undefined || loading) {
      return false;
    }

    let usePackage = false;
    connection.packages.forEach(packageObject => {
      if (packageObject.packageType === packageType) {
        usePackage = true;
      }
    });

    return usePackage;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h1">{!loading && connection.connectionName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonElement}
            onClick={() => handleClickChange(connection)}
          >
            <FormattedMessage id="button.change" />
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonElement}
            disabled={runButtonDisabled}
            onClick={() => handleRunConnectionModule(connectionId)}
          >
            <FormattedMessage id="button.run" />
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonElement}
            component={CustomRouterLink}
            to={`/connection/${connectionId}/insight`}
            exact={page.exact || false}
          >
            <FormattedMessage id="button.insight" />
          </Button>
          {isUserSuperAdmin && (
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/${connectionId}/curls`}
              exact={page.exact || false}
            >
              <FormattedMessage id="button.curls" />
            </Button>
          )}
          {connectionUsesPackage('payment-toggle') && (
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonElement}
              component={CustomRouterLink}
              to={`/connection/payment-toggle/${connectionId}/run-details`}
              exact={page.exact || false}
            >
              <FormattedMessage id="button.paymentToggle.runDetails" />
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<FormattedMessage id="connections.tasks.title" />}
              action={
                <>
                  <Button
                    className={classes.buttonElement}
                    color="primary"
                    variant="outlined"
                    onClick={() => clearTasks(false)}
                    disabled={clearingTasks}
                  >
                    <FormattedMessage id="connectionDetails.clearTasks" />
                  </Button>
                  <Button
                    className={classes.buttonElement}
                    color="primary"
                    variant="outlined"
                    onClick={() => clearTasks(true)}
                    disabled={clearingTasks}
                  >
                    <FormattedMessage id="connectionDetails.forceClearTasks" />
                  </Button>
                </>
              }
            />
            <CardContent>
              <TasksTable
                tasks={tasks}
                loading={loadingTasks || loading}
                paging
                page={page - 1}
                rowsPerPage={pageSize}
                totalCount={totalCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<FormattedMessage id="connections.syncStatus.title" />}
                action={
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.buttonElement}
                      onClick={() => handleClickResetSyncItems(connectionId)}
                    >
                      <FormattedMessage id="button.resetSyncItems" />
                    </Button>

                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => retrieveSyncStatusReport()}
                      className={classes.buttonElement}
                      disabled={loadingSyncStatusReport}
                    >
                      <FormattedMessage id="connections.syncStatus.refresh" />
                    </Button>
                  </>
                }
              />

              <CardContent>
                <SyncStatusTable
                  syncStatusReport={syncStatusReport}
                  loading={loadingSyncStatusReport || loading}
                  isUserSuperAdmin={isUserSuperAdmin}
                />
              </CardContent>
            </Card>

            <Grid item xs={12} />
            <Card className={classes.content}>
              <CardHeader
                title={<FormattedMessage id="connections.logs.title" />}
                action={
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => handleClickUpdateAllLogsStatus(LOG_STATUS.RESOLVED)}
                      className={classes.buttonElement}
                      disabled={updatingAllLogsStatus}
                    >
                      <FormattedMessage id="connectionDetails.markAllLogsAsResolved" />
                    </Button>

                    <Button
                      disabled={updatingSelectedLogsStatus || logs.filter(item => item.selectedRow).length === 0}
                      className={classes.buttonElement}
                      color="primary"
                      variant="outlined"
                      onClick={() => handleClickUpdateSelectedLogsStatus(LOG_STATUS.RESOLVED)}
                    >
                      <FormattedMessage id="connectionDetails.markLogsAsResolved" />
                    </Button>

                    {isUserSuperAdmin && (
                      <Button
                        className={classes.buttonElement}
                        color="primary"
                        variant="outlined"
                        onClick={() => clearLogs(false)}
                        disabled={clearingLogs}
                      >
                        <FormattedMessage id="connectionDetails.clearLogs" />
                      </Button>
                    )}
                    {/* <Tooltip */}
                    {/*  disableHoverListener={checkingDownload || canDownload} */}
                    {/*  title={<FormattedMessage id="connectionDetails.logEntries.noMissingDetails" />} */}
                    {/* > */}
                    {/*  <span> */}
                    {/*    <Button */}
                    {/*      color="primary" */}
                    {/*      variant="outlined" */}
                    {/*      onClick={() => downloadLogs()} */}
                    {/*      disabled={!canDownload} */}
                    {/*    > */}
                    {/*      <FormattedMessage id="connectionDetails.logEntries.download" /> */}
                    {/*    </Button> */}
                    {/*  </span> */}
                    {/* </Tooltip> */}
                  </>
                }
              />
              <CardContent>
                <LogsTable
                  logs={logs}
                  loading={loadingLogs || loading}
                  paging
                  page={pageLogs - 1}
                  rowsPerPage={pageSizeLogs}
                  totalCount={totalCountLogs}
                  handlePageChange={handlePageChangeLogs}
                  handleRowsPerPageChange={handleRowsPerPageChangeLogs}
                  handleToggleAllLogs={handleToggleAllLogs}
                  handleToggleLog={handleToggleLog}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {modal2Open && (
        <ChangeConnectionWizard
          onClose={() => setModal2Open(false)}
          onSubmit={handleChangeConnection}
          connectionId={connectionId}
        />
      )}
    </div>
  );
};

export default ConnectionDetailsPage;
