import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { CREDENTIAL_TYPE_OAUTH, INSURANCE_BRYDGE, NEW_CREDENTIAL_VALUE } from './constants';
import {
  getAvailableCredentialForPackageType,
  getNewCredentialDetails,
  onboardNewCredential,
} from '../../Services/OnboardingService';
import OnboardingCredentialForm from '../Forms/OnboardingCredentialForm/OnboardingCredentialForm';
import { actions as CredentialsAction } from '../../../Credentials/Ducks/Credentials.duck';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';
import { createValidationByKeys } from '../../../../common/YupValidations';
import { FieldAdapter } from '../../../../common/YupValidations/FieldAdapter';

const OnboardingCredentialStep = ({ formRef, onSubmit, packageSelectionData, backButtonPressed, setBackButton }) => {
  const dispatch = useDispatch();
  const { loadedAllItems, credentialDetails, initialCredentials, oldValues, selectedCredentialType } = useSelector(
    state => state.OnboardingReducer,
  );
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState([]);
  const [newCredentialForms, setNewCredentialForm] = useState({});
  const { packages, organizationId } = packageSelectionData;
  const [credentialInitialValues, setCredentialInitialValues] = useState(initialCredentials);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

  console.log(packages);

  const loadCredentialsThatUserHasAccessTo = async packageType => {
    const credentialThatUserHasAccessToResponse = await getAvailableCredentialForPackageType(
      packageType,
      organizationId,
    );

    const CredentialsThatUserHasAccessTo = {
      packageType,
      credentials: credentialThatUserHasAccessToResponse.data['hydra:member'],
    };

    setCredentials(prevState => [...prevState, CredentialsThatUserHasAccessTo]);
    setLoading(false);
  };

  const loadEmptyCredentials = packageType => {
    const data = {
      packageType,
      credentials: [],
    };
    setCredentials(prevState => [...prevState, data]);
  };

  const loadEmptyCredentialsForAvailablePackages = () => {
    const data = [];
    packages.forEach(packageType => {
      data.push({
        packageType,
        credentials: [],
      });
    });

    return data;
  };

  const loadNewCredentialsDetails = async (packageType, selectedCredentialType) => {
    setLoading(true);

    const response = await getNewCredentialDetails(packageType);
    const allCredentialDetails = {};

    response.data['hydra:member'].forEach(item => {
      const { credentialType, fields } = item;
      allCredentialDetails[credentialType] = { fields, credentialType };
    });

    setNewCredentialForm(prevState => {
      const currentState = { ...prevState };
      currentState[packageType] = allCredentialDetails[selectedCredentialType];
      return currentState;
    });

    setLoading(false);
  };

  useEffect(() => {
    packages.forEach(packageType => {
      if (packageType === INSURANCE_BRYDGE) {
        onSelectCredential(NEW_CREDENTIAL_VALUE, INSURANCE_BRYDGE);
        loadEmptyCredentials(packageType);
      } else {
        loadCredentialsThatUserHasAccessTo(packageType);
      }
    });
    if (!isEmpty(newCredentialForms)) {
      newCredentialForms.forEach(packageType => {
        loadCredentialsThatUserHasAccessTo(packageType);
      });
    }
    // eslint-disable-next-line
  }, []);

  const createCredentials = async (values, form) => {
    await Promise.all(
      Object.keys(values.credentials).map(async packageType => {
        const value = values.credentials[packageType];
        if (value === NEW_CREDENTIAL_VALUE) {
          let credentialId;
          if (newCredentialForms[packageType].credentialType === CREDENTIAL_TYPE_OAUTH) {
            credentialId = newCredentialForms[packageType].credentialId;
          } else {
            // create new credential
            const newPackageCredential = {
              packageType,
            };
            if (values.hasOwnProperty('newCredentials') && values.newCredentials.hasOwnProperty(packageType)) {
              newPackageCredential.credential = Object.fromEntries(Object.entries(values.newCredentials[packageType]));
              newPackageCredential.credentialType = values.credentialsDetails[packageType].credentialType;
            }
            if (packageType === INSURANCE_BRYDGE) {
              newPackageCredential.credential = { name: '' };
            }
            const response = await createNewCredential(organizationId, newPackageCredential, form, null);
            credentialId = response.data.credentialId;
          }
          values.credentials[packageType] = credentialId;
        }
      }),
    );
    return values;
  };

  function normalizeCredentialStepErrors(json, packageType) {
    const error = json['hydra:description'] || json['hydra:title'] || 'An error occurred.';
    if (!json.violations) return error;

    const errors = {};
    json.violations.forEach(violation => {
      const errorField = violation.propertyPath.replace('package.credential.', '');
      const errorFieldInsideFrom = `newCredentials.${packageType}.${errorField}`;

      if (errors[violation.propertyPath]) {
        errors[errorFieldInsideFrom] += `\n ${violation.message}`;
      } else {
        errors[errorFieldInsideFrom] = violation.message;
      }
    });

    return errors;
  }

  const handleCreateCredential = async (newCredential, form, setFieldError) => {
    try {
      return await onboardNewCredential(newCredential);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeCredentialStepErrors(e.response.data, newCredential.package.packageType);

        if (form && typeof errors === 'string') {
          form.setStatus(errors);
        }

        if (form) {
          Object.keys(errors).forEach(path => {
            form.setFieldError(path, errors[path]);
          });

          form.setSubmitting(false);
        } else if (setFieldError) {
          Object.keys(errors).forEach(path => {
            setFieldError(path, errors[path]);
          });
        }
      }
    }
  };

  const createNewCredential = async (organizationId, newPackageCredential, form, setFieldError) => {
    const credentialId = v4();
    const credentialPackage = newPackageCredential.packageType;
    if (credentialPackage !== INSURANCE_BRYDGE) {
      dispatch(OnboardingActions.RemoveInitialValueForCredential({ packageType: credentialPackage }));
      dispatch(OnboardingActions.SetInitialValueForCredentials({ packageType: credentialPackage, credentialId }));
    }
    if (newCredentialForms[newPackageCredential.packageType].credentialType === CREDENTIAL_TYPE_OAUTH) {
      newPackageCredential.credentialRequest = {
        ...newPackageCredential.credential,
        successUrl: `${window.location.origin}/credential/oauth/success/${credentialId}`,
        failureUrl: `${window.location.origin}/credential/oauth/error/${credentialId}`,
      };
    }

    const newCredential = {
      organizationId,
      package: newPackageCredential,
      credentialId,
    };

    return await handleCreateCredential(newCredential, form, setFieldError);
  };

  const onSubmitForm = async (values, form) => {
    setLoading(true);

    try {
      const newValues = await createCredentials(values, form);
      setLoading(false);
      dispatch(CredentialsAction.removeOauthStatus());
      onSubmit(newValues);

      dispatch(OnboardingActions.RemoveSelectedCredentialTypes());
    } catch (e) {
      setLoading(false);
    }
  };

  const handleConnect = async (packageType, values, event, setFieldError) => {
    event.preventDefault();
    setLoading(true);

    const newPackageCredential = {
      packageType,
      credentialType: values.credentialsDetails[packageType].credentialType,
      credential: values.newCredentials[packageType],
    };

    try {
      const response = await createNewCredential(organizationId, newPackageCredential, null, setFieldError);
      const { credentialId } = response.data;
      setNewCredentialForm(prevState => {
        const currentState = { ...prevState };
        currentState[packageType].credentialId = credentialId;
        return currentState;
      });
      dispatch(OnboardingActions.SetInitialValueForCredentials({ packageType, credentialId }));

      window.open(`${process.env.REACT_APP_AUTH_URL}/connect/${packageType}/${credentialId}`, '_blank');
    } catch (e) {
      setLoading(false);
    }
  };

  const setInitialValues = () => {
    packages.forEach(packageType => {
      setCredentialInitialValues(prevState => {
        const currentState = { ...prevState };
        const defaultValue = packageType === INSURANCE_BRYDGE ? NEW_CREDENTIAL_VALUE : initialCredentials[packageType];
        set(currentState, ['credentials', packageType], defaultValue);
        return currentState;
      });
    });
  };

  const getValue = (detail, field) => {
    let fieldValue = '';
    Object.entries(oldValues.values.newCredentials[detail]).forEach(entry => {
      const [key, value] = entry;
      if (key === field) {
        fieldValue = value;
      }
    });
    return fieldValue;
  };

  const setValidations = () => {
    let initialSchema = Yup.object().shape({});

    // Add the validations for the selected credential field
    Object.keys(credentialDetails).forEach(packageType => {
      const credentialValidation = createValidationByKeys([packageType, 'credentials'], Yup.string().required());
      initialSchema = initialSchema.concat(credentialValidation);
    });

    // Add the validations for the selected credential type fields
    Object.keys(credentialDetails).forEach(packageType => {
      if (!selectedCredentialType || !selectedCredentialType[packageType]) {
        return;
      }

      const selectedCredentialTypeOfPackage = selectedCredentialType[packageType];

      credentialDetails[packageType][selectedCredentialTypeOfPackage].fields.forEach(field => {
        field.conditiondBy = 'hidden';
        const fieldAdapter = FieldAdapter(field);
        const fieldValidation = fieldAdapter.getValidation(field);
        const objectValidation = createValidationByKeys(
          [field.fieldName, packageType, 'newCredentials'],
          fieldValidation,
        );

        initialSchema = initialSchema.concat(objectValidation);
      });
    });

    setValidationSchema(initialSchema);
  };

  // const selInitialValues = () => {
  //   Object.keys(credentialDetails).forEach(packageType => {
  //     if (!selectedCredentialType[packageType]) {
  //       return;
  //     }
  //
  //     const selectedCredentialTypeOfPackage = selectedCredentialType[packageType];
  //
  //     credentialDetails[packageType][selectedCredentialTypeOfPackage].fields.forEach(field => {
  //       field.conditiondBy = 'hidden';
  //       const fieldAdapter = FieldAdapter(field);
  //
  //       let initialValue = fieldAdapter.getInitialValue();
  //       if (
  //         !isEmpty(oldValues) &&
  //         !isEmpty(oldValues.values) &&
  //         oldValues.values.newCredentials.hasOwnProperty(packageType)
  //       ) {
  //         initialValue = fieldAdapter.getInitialValue(getValue(packageType, field.fieldName));
  //       }
  //       setCredentialInitialValues(prevState => {
  //         const currentState = { ...prevState };
  //         set(currentState, ['newCredentials', packageType, field.fieldName], initialValue);
  //         return currentState;
  //       });
  //     });
  //   });
  // };

  // Update the credential form validations
  useEffect(() => {
    if (credentialDetails || selectedCredentialType) {
      // selInitialValues();
      setValidations();
    }
  }, [selectedCredentialType]);

  const onSelectCredentialType = (selectedCredentialId, packageType, selectedCredentialType) => {
    if (selectedCredentialId === NEW_CREDENTIAL_VALUE) {
      // Here we update the NewCredentialForms
      loadNewCredentialsDetails(packageType, selectedCredentialType);
    }

    setValidations();

    dispatch(OnboardingActions.SetSelectedCredentialType({ packageType, credentialType: selectedCredentialType }));
  };

  useEffect(() => {
    if (loadedAllItems) {
      setInitialValues();
      setLoading(true);
      setValidations();
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [loadedAllItems]);

  useEffect(() => {
    // initialCredentials, and CredentialInitialValues hold eiter the selected credential id or NEW_CREDENTIAL_VALUE
    Object.keys(initialCredentials).forEach(packageType => {
      setCredentialInitialValues(prevState => {
        const currentState = { ...prevState };
        set(currentState, ['credentials', packageType], initialCredentials[packageType]);
        return currentState;
      });
    });
  }, [initialCredentials]);

  // value could either contain the NEW_CREDENTIAL_VALUE or credentialId in case when an existing credential is selected
  const onSelectCredential = (value, packageType) => {
    if (value !== NEW_CREDENTIAL_VALUE) {
      dispatch(OnboardingActions.RemoveSelectedCredentialType(packageType));
    }

    setValidations();

    setNewCredentialForm(prevState => {
      const currentState = { ...prevState };
      delete currentState[packageType];

      return currentState;
    });

    dispatch(OnboardingActions.SetInitialValueForCredentials({ packageType, credentialId: value }));
  };

  useEffect(() => {
    setValidations();
  }, []);

  return (
    <OnboardingCredentialForm
      formRef={formRef}
      loading={loading}
      emptyPackageCredentials={loadEmptyCredentialsForAvailablePackages()}
      initialValues={credentialInitialValues}
      packageCredentials={credentials}
      packageCredentialDetails={credentialDetails}
      newCredentialForms={newCredentialForms}
      onSubmit={onSubmitForm}
      onSelectCredential={onSelectCredential}
      onSelectCredentialType={onSelectCredentialType}
      handleConnect={handleConnect}
      setLoading={setLoading}
      validationSchema={validationSchema}
      backButtonPressed={backButtonPressed}
      setBackButton={setBackButton}
    />
  );
};

OnboardingCredentialStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  packageSelectionData: PropTypes.object.isRequired,
};

export default OnboardingCredentialStep;
