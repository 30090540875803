import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, set } from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingSettingsForm from '../Forms/OnboardingSettingsForm/OnboardingSettingsForm';
import {
  getConnectionSettings,
  getStatusOfInitialRun,
} from '../../Services/OnboardingService';
import { FieldAdapter } from '../../../../common/YupValidations/FieldAdapter';
import { createValidationByKeys } from '../../../../common/YupValidations';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';

const OnboardingConnectionSettingsStep = ({ formRef, onSubmit, connection, defaults, useDelay }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tasksWithStatuses, setTasksWithStatuses] = useState([]);
  const [settings, setSettings] = useState([]);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const { oldValues } = useSelector(state => state.OnboardingReducer);

  const setInitialValuesAndValidations = () => {
    if (!isEmpty(oldValues) && !isEmpty(oldValues.values)) {
      dispatch(OnboardingActions.SetCanGoBack(true));
    }
    dispatch(OnboardingActions.SetValues({}));
    if (!settingsLoaded) {
      return;
    }
    let initialSchema = Yup.object().shape({});
    setLoading(true);
    settings.forEach(packageSetting =>
      packageSetting.forEach(credential => {
        credential.fields.forEach(field => {
          const fieldAdapter = FieldAdapter(field);
          const fieldValidation = fieldAdapter.getValidation();
          const objectValidation = createValidationByKeys(
            [field.fieldName, credential.packageType, 'settings'],
            fieldValidation,
          );
          initialSchema = initialSchema.concat(objectValidation);

          let initialValue;
          if (!isEmpty(defaults[credential.packageType])) {
            initialValue = defaults[credential.packageType][field.fieldName];
          }

          if (!initialValue) {
            initialValue = fieldAdapter.getInitialValue(initialValue);
          }
          setInitialValues(prevState => {
            const currentState = Object.assign({}, prevState);
            set(currentState, ['settings', credential.packageType, field.fieldName], initialValue);
            return currentState;
          });
        });
      }),
    );
    setValidationSchema(initialSchema);
    setLoading(false);
  };

  const doesConnectionUsePackage = packageType => {
    return connection.packages.some(p => p.packageType === packageType);
  };

  useEffect(() => {
    if (isEmpty(settings)) {
      (async () => {
        setLoading(true);
        dispatch(OnboardingActions.SetLoading(true));
        if (useDelay) {
          let initialRunStatus = 'pending';
          let previousAmountOfTasks = 0;
          let amountOfPullsWithoutUpdates = 0;

          while (initialRunStatus !== 'complete' && initialRunStatus !== 'failed') {
            // eslint-disable-next-line no-await-in-loop
            const response = await getStatusOfInitialRun(connection.connectionId);
            console.log('Checking status of initial run', response.data);

            if (response.data['hydra:member'].length > 0) {
              const responseFirstMember = response.data['hydra:member'][0];
              setTasksWithStatuses(responseFirstMember.tasks);
              initialRunStatus = responseFirstMember.status;

              if (previousAmountOfTasks !== responseFirstMember.tasks.length) {
                previousAmountOfTasks = responseFirstMember.tasks.length;
                amountOfPullsWithoutUpdates = 0;
              } else {
                amountOfPullsWithoutUpdates++;
              }

              if (amountOfPullsWithoutUpdates > 30) {
                initialRunStatus = 'failed';
              }
            }

            // eslint-disable-next-line no-await-in-loop
            await new Promise(resolve => setTimeout(resolve, 4000));
          }
        }

        const response = await getConnectionSettings(connection.connectionId);
        setSettings(prevState => [...prevState, response.data['hydra:member']]);
        setLoading(false);
        dispatch(OnboardingActions.SetLoading(false));
        setSettingsLoaded(true);
      })();
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (settingsLoaded) {
      const newValues = { ...initialValues, settings: initialValues.settings || {} };
      connection.packages.forEach(p => {
        newValues.settings[p.packageType] = Object.keys(p.settings).length > 0 ? p.settings : {};
      });
      setInitialValues(newValues);
      setInitialValuesAndValidations();
    }
    // eslint-disable-next-line
  }, [settingsLoaded]);

  return (
    <OnboardingSettingsForm
      settings={settings}
      onSubmit={onSubmit}
      loading={loading}
      formRef={formRef}
      initialValues={initialValues}
      settingsLoaded={settingsLoaded}
      validationSchema={validationSchema}
      tasksWithStatuses={tasksWithStatuses}
    />
  );
};

OnboardingConnectionSettingsStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  connection: PropTypes.object.isRequired,
  defaults: PropTypes.array,
};

OnboardingConnectionSettingsStep.defaultProps = {
  defaults: [],
};

export default OnboardingConnectionSettingsStep;
