import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { startCase, isEmpty } from 'lodash';
import RenderFieldComponent from '../RenderFieldComponent';
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OnboardingSettingsForm = ({
  settings,
  onSubmit,
  loading,
  formRef,
  initialValues,
  validationSchema,
  disabled,
  tasksWithStatuses,
}) => {
  const classes = useStyles();

  const renderFields = sett =>
    sett.fields.map(field => (
      <RenderFieldComponent
        disabled={disabled}
        key={field.fieldName}
        path={`settings.${sett.packageType}.${field.fieldName}`}
        packageType={sett.packageType}
        field={field}
      />
    ));

  const getVerbVersionOfTaskStatus = status => {
    switch (status) {
      case 'complete':
        return 'Finished';
      default:
        return startCase(status);
    }
  };
  // colorPrimary
  // colorSecondary
  // colorTextPrimary
  // colorTextSecondary
  const renderTasksWithStatuses = () =>
    tasksWithStatuses.map((task, index) => {
      return (
        <Grid item xs={12} key={`task-status-index-${index}`}>
          <Typography variant={task.taskStatus === 'complete' ? 'h6' : 'body2'}>
            {'- '}
            {getVerbVersionOfTaskStatus(task.taskStatus)} {startCase(task.shortModuleClassName)}
          </Typography>
        </Grid>
      );
    });

  const gridItems = () =>
    settings
      .filter(credentialSettings => credentialSettings.length > 0)
      .sort((a, b) => a[0].packageType.localeCompare(b[0].packageType))
      .map(credentialSettings =>
        credentialSettings
          .filter(sett => sett.fields.length > 0)
          .map(sett => {
            return (
              <React.Fragment key={sett.packageType}>
                <Typography variant="h4">{startCase(sett.packageType.replace('-', ' '))}</Typography>

                <Grid container spacing={3}>
                  {renderFields(sett)}
                </Grid>
              </React.Fragment>
            );
          }),
      );

  const handleSubmit = async (values, form) => {
    const newValues = { ...values, settings: { ...values.settings } };
    settings.forEach(credentialSettings => {
      credentialSettings.forEach(sett => {
        const newPackageValue = { ...values.settings[sett.packageType] };
        sett.fields.forEach(field => {
          if (field.fieldType === 'stringMap') {
            const fieldValue = newValues.settings[sett.packageType][field.fieldName];
            const newFieldValue = {};
            fieldValue.forEach(({ key, value }) => {
              newFieldValue[key] = value;
            });

            newPackageValue[field.fieldName] = newFieldValue;
          } else if (field.fieldType === 'float') {
            const fieldValue = newValues.settings[sett.packageType][field.fieldName];
            newPackageValue[field.fieldName] = Number.isInteger(fieldValue) ? fieldValue.toFixed(1) : fieldValue;
          }
          newValues.settings[sett.packageType] = newPackageValue;
        });
      });
    });

    onSubmit(newValues, form);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {loading && (
            <Typography>
              <FormattedMessage id="setting.loading" />
            </Typography>
          )}
            {loading && <LinearProgress />}
          {loading && (
            <Grid item className={classes.root} spacing={3}>
              <Box width={'100%'}>
                <Typography>&nbsp;</Typography>
              </Box>
              <Box width="100%">
              {renderTasksWithStatuses()}
              </Box>
            </Grid>
          )}
        </Grid>
        {!isEmpty(initialValues) && (
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              <Form ref={formRef}>
                <Grid container className={classes.root} spacing={3}>
                  <Grid item xs={12}>
                    {gridItems()}
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        )}
      </Grid>
    </>
  );
};

OnboardingSettingsForm.propTypes = {
  settings: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OnboardingSettingsForm;
